.services-page {
    font-family: 'Red Hat Display', sans-serif;
    text-align: center;
    padding: 50px;
    /* background-color: #f7f7f7; Assuming a light background for the services page */

    background: rgba(0, 0, 0, 0.7);
    margin: 20px; /* Space between the edge of the screen and the section */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    backdrop-filter: blur(10px); /* Blur effect for the background content */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    position: relative; /* To position pseudo-elements */
    overflow: hidden; /* To contain pseudo-elements */
    border-radius: 20px; /* Rounded corners */
  }
  
  .services-page h1 {
    font-size: 2.5rem;
    text-shadow: 0 0 20px rgba(255, 213, 0, 0.75); /* Neon text shadow */
    margin-bottom: 1rem;
    color: white;
  }
  
  .services-page p {
    font-size: 1.25rem;
    color: white;
    margin-bottom: 2rem;
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px; /* Space between service items */
  }

  .service-item-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px;
  }
  
  .service-item {
    /* width: 30%; */
    /* background: linear-gradient(145deg, #ffffff, #e6e6e6); */
    /* padding: 20px; */
    /* border-radius: 8px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex; /* Make it a flex container */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    width: 30%;
    padding: 20px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    height: 300px;   
    
  }

  .service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* background: rgba(0, 0, 0, 0.8); */
  }
  
  .service-item img {
    display: none; /* Hide the img element since we are using background images */
  }

  /* .service-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
   */

   .service-item::before {
    content: ""; /* necessary for a pseudo-element */
    display: block; /* make it a block-level element */
    position: absolute; /* position it absolutely within .service-item */
    top: 0; /* align to the top of the container */
    left: 0; /* align to the left of the container */
    right: 0; /* align to the right of the container */
    bottom: 0; /* align to the bottom of the container */
    background: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
    z-index: 1; /* ensure it's above the background image but below the text */
  }

  .service-item * {
    position: relative; /* make all children within .service-item stack above the pseudo-element */
    z-index: 2; /* higher z-index to be on top of the overlay */
  }

  .service-item h2 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 0.5rem;
  }
  
  .service-item p {
    font-size: 1rem;
    color: white;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .services-list {
      flex-direction: column;
      align-items: center;
    }
  
    .service-item {
      width: 100%;
      margin: 0;
    }
  }