.contact-page {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Red Hat Display', sans-serif;
  padding: 50px;
  color: white;
}

.contact-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  backdrop-filter: blur(10px); /* Blur effect for the background content */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  position: relative; /* To position pseudo-elements */
  overflow: hidden; /* To contain pseudo-elements */
  
}

.contact-image-container {
  flex: 1;  
}

.contact-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  
}

.contact-form-container a {
  color: white;
}

.contact-form-container {
  flex: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form textarea {
  resize: none;
}

.contact-form label {
  margin-bottom: 5px;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-submit-button {
  padding: 15px 30px;
  background-color: #0062cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;
}

.contact-submit-button:hover {
  background-color: #004f9f;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    margin: 10px;
  }

  .contact-image {
    display: none;
  }

  .contact-form-container {
    padding: 30px;
  }

  .contact-form {
    width: 100%;
  }

  .contact-page {
    padding: 20px;
  };
}
