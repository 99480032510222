.footer {
    background: black; /* Same as the header for consistency */
    color: #ecf0f1; /* Light color for the text */
    padding: 20px;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1200px; /* Adjust based on your design preference */
    margin: 0 auto;
  }
  
  .logo-container-header {
    flex: 1; /* Gives it the ability to grow and shrink as needed */
    display: flex;
    align-items: center;
  }

  .logo-container-footer {
    flex: 0; /* Gives it the ability to grow and shrink as needed */
    display: flex;
    align-items: center;
  }
  
  .footer-text {
    flex: 2; /* Allows this section to take up twice as much space as the logo */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers the content vertically */
    align-items: center; /* Centers the content horizontally */
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px; /* Adds some space above the links */
  }
  
  .logo-link {
    display: flex;
    align-items: center;
  }
  
  .logo-img-footer {
    height: 200px; /* Adjust as needed */
    /* width: auto; */
  }
  
  .footer-links a {
    color: #ecf0f1;
    text-decoration: none;
    margin-right: 15px; /* Spacing between links */
    transition: color 0.3s ease;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }

  .areas-served-container {
    /* Adjust the width as needed */
    width: auto;
    flex: 1;
    padding: 20px;
    color: #ecf0f1; /* Light color for text */
  }
  
  .areas-served-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 columns */
    grid-template-rows: repeat(3, 1fr); /* Creates 3 rows */
    gap: 10px;
    padding: 3%;
    width: auto;
    list-style: none;
  }

  .areas-served-grid div {
    background: #ecf0f1; /* Light background for each item */
    padding: 10px;
    text-align: center;
  }
  
  .areas-served-grid li {
    white-space: nowrap;
  }
  
  .map-container {
    /* Existing styles */
    padding-left: 3%;
    width: auto; /* Adjust width to account for the areas-served-container */
  }
  
  .footer-links a:hover {
    color: #3498db; /* Highlight color on hover */
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }
      
    .logo-container-footer,
    .footer-text,
    .footer-links {
        flex: none; /* Don't allow the items to grow or shrink */
        align-items: center; /* Align items in the center for mobile view */
        justify-content: center; /* Justify content in the center for mobile view */
    }
    
    .footer-text,
    .footer-links {
        width: 100%; /* Full width for mobile view */
        margin-bottom: 20px;
    }
  
    .logo-container-footer {
        margin-bottom: 10px;
        display: none; /* Hide the logo on mobile */
    }

    .areas-served-container,
    .map-container {
      width: 100%;
    }
  
    .areas-served-grid {
      grid-template-columns: 1fr; /* Stacks items in one column on mobile */
      grid-template-rows: repeat(6, 1fr); /* Creates 6 rows for each item */
    }

    .footer-block {
      order: 4;
    }
  
    .areas-served-container {
      order: 3; /* Move the areas served above the map on mobile */
    }
  }
  