@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&display=swap');

.App {
  text-align: center;
  font-family: 'Red Hat Display', sans-serif;
  background-image: url('./static/assets/new_background.jpg'); /* Replace with your image path */
  background-size: cover;
  background-attachment: fixed; /* This will make the background fixed */
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Minimum height to take full viewport height */
}
