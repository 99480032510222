.hero {
    position: relative;
    height: 40vh;
    background-color: #000; /* Fallback color */
    overflow: hidden;
  }
  
  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the area without stretching the image */
    filter: brightness(0.75); /* Dim the image a bit */
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000 100%); /* Adjust the color to match your page's background */
  }

  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the content */
    color: #fff; /* White text color */
    text-align: center;
  }

  
  .hero-text h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    text-shadow: 0 0 20px rgba(255, 213, 0, 0.75); /* Neon text shadow */
  }
  
  .hero-text p {
    font-size: 1.25rem;
  }

  /* Style for the 'Get a Quote' button */
  .quote-button {
    padding: 10px 20px;
    margin: 30px;
    background-color: #0062cc; /* Example button color */
    color: #fff;
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease; /* Transition for hover effect */
    font-family: "Red Hat Display", sans-serif;
  }

  .quote-button:hover {
    background-color: #d09822; /* Darken button color on hover */
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .hero-text h1 {
      font-size: 2rem;
    }
  
    .hero-text p {
      font-size: 1rem;
    }
  }
  