.gallery-page {
    font-family: 'Red Hat Display', sans-serif;
    text-align: center;
    padding: 50px;

    background: rgba(0, 0, 0, 0.7);
    margin: 20px; /* Space between the edge of the screen and the section */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    backdrop-filter: blur(10px); /* Blur effect for the background content */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    position: relative; /* To position pseudo-elements */
    overflow: hidden; /* To contain pseudo-elements */
    border-radius: 20px; /* Rounded corners */
  }
  
  .gallery-page h1 {
    font-size: 2.5rem;
    text-shadow: 0 0 20px rgba(255, 213, 0, 0.75); /* Neon text shadow */
    color: white;
    margin-bottom: 1rem;
  }
  
  .gallery-page p {
    font-size: 1.25rem;
    color: white;
    margin-bottom: 2rem;
    padding: 10px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    gap: 20px; /* Space between gallery items */
  }

  .gallery-grid p {
    color: black;
  }
  
  .gallery-item {
    background-color: #fff; /* White background for each gallery item */
    border-radius: 25px; /* Rounded corners for styling */
    overflow: hidden; /* Ensures images fit within the border radius */
    height: 300px;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%; /* Set height to 100% as well */
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
    display: block;
    min-width: 100%; /* Ensure it covers the full width */
    min-height: 100%; /* Ensure it covers the full height */
  }
  
  .gallery-item img:hover {
    transform: scale(1.1); /* Zoom effect on hover */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .gallery-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjusts to fewer columns on smaller screens */
    }
  }
  