.info-section {
    text-align: center;
    padding: 50px;

    background: rgba(0, 0, 0, 0.7); /* Smokey look with semi-transparent black */
    border-radius: 20px; /* Rounded corners */
    padding: 40px; /* Padding inside the section */
    margin: 20px; /* Space between the edge of the screen and the section */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    backdrop-filter: blur(10px); /* Blur effect for the background content */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    position: relative; /* To position pseudo-elements */
    overflow: hidden; /* To contain pseudo-elements */
    color: #fff; /* Text color */
  }

  .info-section h1 {
    text-shadow: 0 0 20px rgba(255, 213, 0, 0.75); /* Neon text shadow */
    margin-bottom: 20px;
  }
  
  .services-container {
    display: flex;
    justify-content: space-between; /* Space out the service divs */
    margin: 40px;
  }
  
  .service {
    flex-basis: 30%; /* Adjust the width of each service div */
    text-align: center;
    background: grey; /* Dark background for each service card */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    color: white;
  }

  .service:hover {
    transform: translateY(-20px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  }
  
  .service img {
    margin-bottom: 15px; /* Space below the image */
    height: 100px; /* Adjust width as needed */
    width: auto; /* Adjust height to maintain aspect ratio */
    animation: spark 1.5s infinite alternate;
    border-radius: 50%;
  }
  
  .service h2 {
    margin-bottom: 10px; /* Space below the h2 */
  }
  
  .service p {
    font-size: 14px; /* Adjust font size as needed */
  }
  
  .about-us-button {
    padding: 10px 20px;
    margin: 30px;
    color: #fff;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    font-size: 1rem;
    font-family: "Red Hat Display", sans-serif;
    background: linear-gradient(45deg, #007bff, #004f9f);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transition: background-color 1s ease; /* Transition for hover effect */
    border: none
  }
  
  .about-us-button:hover {
    /* background-color: #d09822; */
    background: #d09822; /* Darken button on hover */
  }

  @keyframes spark {
    from { opacity: 0.7; transform: translateY(0); }
    to { opacity: 1; transform: translateY(-5px); }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .services-container {
      flex-direction: column;
      margin: 0;
      margin-top: 50px;
    }
  
    .service {
      flex-basis: 100%; /* Each service takes full width */
      margin-bottom: 20px; /* Space between stacked services */
    }
  }
  