.about-page {
  font-family: 'Red Hat Display', sans-serif;
  text-align: center;
  padding: 50px;

  background: rgba(0, 0, 0, 0.4);
  margin: 20px; /* Space between the edge of the screen and the section */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  backdrop-filter: blur(10px); /* Blur effect for the background content */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  position: relative; /* To position pseudo-elements */
  overflow: hidden; /* To contain pseudo-elements */
  border-radius: 20px; /* Rounded corners */

}

.content-with-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.7);
  padding: 50px;
  margin-left: 15%;
  margin-right: 15%;
}

.content-with-image section,
.content-with-image .about-image,
.content-with-image .values-image,
.content-with-image .team-image {
  flex-basis: 48%;
  margin: 1%;
}

.content-with-image img {
  max-width: 100%;
  border-radius: 8px; /* Rounded corners for images */
}

/* Style adjustments for sections */
.about-hero h1 {
  text-align: center;
  text-shadow: 0 0 20px rgba(255, 213, 0, 0.75); /* Neon text shadow */
  margin-bottom: 1rem;
  /* ... */
}

/* Text and headers */
.about-hero h1, .about-content h2, .about-values h2, .about-team h2 {
  font-size: 2.5rem;
  color: white;
  /* ... */
}

.about-hero p {
  margin-bottom: 2rem;
}

.about-hero p, .about-content p, .about-values p, .about-team p {
  font-size: 1.25rem;
  color: white;
  

  /* ... */
}

.about-image img,
.values-image img {
  width: 200px;
  height: 200px;
}

/* Responsive design */
@media (max-width: 768px) {
  .content-with-image {
    flex-direction: column;
  }

  .content-with-image section,
  .content-with-image .about-image,
  .content-with-image .values-image,
  .content-with-image .team-image {
    flex-basis: 100%;
  }

  .about-hero, .about-content, .about-values, .about-team {
    padding: 20px;
    /* ... */
  }

  .about-hero h1, .about-content h2, .about-values h2, .about-team h2 {
    font-size: 2rem;
    /* ... */
  }

  .about-hero p, .about-content p, .about-values p, .about-team p {
    font-size: 1rem;
    /* ... */
  }

  .about-image, .values-image, .team-image {
    order: 1; /* Image comes second */
  }

  .about-content, .about-values, .about-team {
    order: 2; /* Text comes first */
  }

  .content-with-image {
    margin-left: 5%;
    margin-right: 5%;
    padding: 0;
  }

  
}
